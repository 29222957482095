import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { detailquestion_seperate } from "../../../api/actions/detailQuestion";
import desktop from "../../Home/banner/assets/images/desktops.png";
import Helmet from "react-helmet";
import { IconContext } from "react-icons";
import queryString from "query-string";
import ShareLink from "react-facebook-share-link";
import { FaFacebook } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { TwitterShareButton, TwitterIcon } from "react-share";
export default function Shares({ title, link, _id }) {
  console.log("ID TWITTER", _id);
  const [button, setButton] = useState("");
  // useEffect(() => {
  //   <Helmet>
  //     <script
  //       async
  //       src="https://platform.twitter.com/widgets.js"
  //       charset="utf-8"
  //     ></script>
  //   </Helmet>;
  // }, [_id]);

  // const renderTwitter = () => {
  //   <Helmet>
  //     <script
  //       async
  //       src="https://platform.twitter.com/widgets.js"
  //       charset="utf-8"
  //     ></script>
  //   </Helmet>;
  // };
  return (
    <>
      <Dropdown.Item>
        {" "}
        {/* <div className="share-tweet"> */}
        {/* <a
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="twitter-share-button "
            data-text={title > 240 ? title.substring(0, 240) + "..." : title}
            data-size="large"
            data-url={
              "https://fertility.medanswers.com/questions/detail?id=" + _id
            }
            data-show-count="false"
          >
            Tweet
          </a> */}
        <TwitterShareButton
          url={link}
          title={title > 240 ? title.substring(0, 240) + "..." : title}
          className="Demo__some-network__share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        {/* </div> */}
        {/* <Helmet>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </Helmet> */}
      </Dropdown.Item>

      <ShareLink
        link={link}
      >
        {(link) => (
          <a
            onClick={() => {
              typeof window !== "undefined" &&
                window.open(link, "_blank", "resizable,height=600,width=800");
            }}
            className="facebook-share"
          >
            <IconContext.Provider
              value={{
                color: "#fff",
                className: "global-class-name",
              }}
            >
              <div>
                <FaFacebook />
              </div>
            </IconContext.Provider>{" "}
            {/* Share */}
          </a>
        )}
      </ShareLink>
    </>
  );
}
