import React, { useState } from "react";
import { Link } from "gatsby";
import Choose from "../../layout/chooseRole/index.js";

export default function Heading({ heading, subheading, link = {} }) {
  const [choosebox, setchoosebox] = useState(false);
  return (
    <>
      <div className="Headng">
        <h1>{heading}</h1>

        <h3>
          {subheading}
          <a
            onClick={() => {
              setchoosebox(true);
            }}
          >
            {" "}
            {link}
          </a>
        </h3>
      </div>
      {choosebox && <Choose status={choosebox} setchoosebox={setchoosebox} />}
    </>
  );
}
