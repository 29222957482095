import React from "react";
import { Link } from "gatsby";
import { getTextFromHTML } from '../../../../utils/utils';

export default function data({
  userimage,
  username,
  question,
  answer,
  thumbnail,
  id,
}) {
  let parsedAnswer = getTextFromHTML(answer)

  return (
    <div className="slider-box">
      {" "}
      <p>{question}</p>
      <div className="post">
        <img src={userimage} alt="expert avatar" />
        <h4>{username}</h4>

        <Link
          to={`/popular-questions/details/${id}`}
        >
          <p>
            {parsedAnswer.length > 150 ?
              parsedAnswer.substr(0, 150) + `...` :
              parsedAnswer
            }
            <span> (read more)</span>
          </p>
        </Link>
        {thumbnail && <img src={thumbnail} className="thumbnail" alt="thumbnail" />}
      </div>
    </div>
  );
}
