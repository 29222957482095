import React from "react";
import share from "../assets/images/ans.svg";
import answer from "../assets/images/message.svg";
import ShareLink from "react-facebook-share-link";
import { FaFacebook } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import Helmet from "react-helmet";
import { IconContext } from "react-icons";
import InnerSocial from "../detailsanswer/shares.js";
export default function Social({ replies, title, tag, tag_title, _id }) {
  return (
    <div className="Social">
      <div className="found-socials">
        {replies && (
          <>
            <img src={answer} />
            <span>{replies} ANSWERS</span>
          </>
        )}

        {/*
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={share} />
              <span> SHARE</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <InnerSocial title={title} _id={_id} />
            </Dropdown.Menu>
          </Dropdown>
        */}
      </div>
      <img title={tag_title} src={tag} />
    </div>
  );
}
