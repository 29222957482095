import API from "../utils/axios";
import { getTokenFromLocalStorage } from "../utils/local-storage";

var all_detail_questions = [];
var url;
var headers;

export const Allquestions = async (data) => {
  const authExist = getTokenFromLocalStorage();

  url = "/questions/?page_size=10&page_number=" + data;

  all_detail_questions = [];
  const response = await API.get(url, {
    headers,
  })
    .then((res) => res)
    .catch((err) => err);

  if (authExist === "") {
    if (response.data.data.length > 0) {
      const all_questions_id = response.data.data.map((data) => data.id);

      const check_all_ids_for_questions = new Promise((res, rej) => {
        all_questions_id.map((data, count) => {
          API.get("/questions/public/" + data).then((x) => {
            all_detail_questions.push(x.data);

            if (count === all_questions_id.length - 1) {
              res();
            }
          });
        });
      });

      await check_all_ids_for_questions;

      return all_detail_questions;
    } else {
      return [];
    }
  }

  if (authExist != "") {
    headers = {
      "Content-Type": "application/json",
      authorization: `Token ${authExist} `,
    };
    if (response.data.data.length > 0) {
      const all_questions_id = response.data.data.map((data) => data.id);

      const check_all_ids_for_questions_reg = new Promise((res, rej) => {
        all_questions_id.map((data, count) => {
          API.get(`/questions/${data}`, { headers }).then((x) => {
            all_detail_questions.push(x.data);

            if (count === all_questions_id.length - 1) {
              res();
            }
          });
        });
      });

      await check_all_ids_for_questions_reg;

      return all_detail_questions;
    } else {
      return [];
    }
  }
};

export const Allquestionsmeta = async (data) => {
  const authExist = getTokenFromLocalStorage();

  url = "/questions/";

  all_detail_questions = [];
  const response = await API.get(url, {
    headers,
  })
    .then((res) => res)
    .catch((err) => err);

  
};
