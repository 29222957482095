import React, { useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
var selected_cat = [];
export default function Categories({
  image,
  title,
  activeCount,
  setloader,
  filter,
  fullobject,
}) {
  var selectCat = (e, fullobject) => {
    if (selected_cat.includes(fullobject)) {
      selected_cat = selected_cat.filter(
        (x) => x.description != fullobject.description
      );
    } else {
      selected_cat.push(fullobject);
    }
    setloader(true);
    document
      .getElementsByClassName("Categories " + e)[0]
      .classList.toggle("active");
    filter(selected_cat);
  };

  return (
    <div
      className={"Categories " + activeCount}
      onClick={selectCat.bind(this, activeCount, fullobject)}
    >
      <div style={{ display: "flex" }}>
        <img src={image} alt="category icon" />
        <h3>{title}</h3>
      </div>
      <Checkbox
        defaultChecked
        value="secondary"
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
}
