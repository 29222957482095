import React from "react";

export default function User({ affiliation, address, img, name, designation }) {
  return (
    <div className="User">
      <div className="imgadjust" style={{ backgroundImage: `url(${img})` }} />
      <h3>{name}</h3>

      <h4>
        {designation.length > 0 &&
          designation.split(", ")[0] + " at " + affiliation}
      </h4>
      <h4>{address}</h4>
      {designation.length > 0 && <h4>specialities: {designation}</h4>}
    </div>
  );
}
