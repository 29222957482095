import React, { useState, useEffect } from "react";
import PopularQuestions from "../Home/popularQuestions";
import { Row, Col, Dropdown } from "react-bootstrap";
import "./assets/css/style.scss";
import { Allquestions } from "../../api/actions/allquestions";
import { allCategories } from "../../api/actions/allcategories";
import Categories from "./Categories/categories.js";
import Post from "./questionPost/post.js";
import Heading from "./heading/heading.js";
import { useDispatch, useSelector } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import { addAllQuestions } from "../../state/app.js";
import Skeleton from "react-loading-skeleton";
import { filterQuestions } from "../../api/actions/filterQuestions.js";
var filterpagecounter = 0;
var pagination_counter = 0;
var filtertiondataStorage = [];

export default function MainHome({ location }) {
  const dispatch = useDispatch();

  const [all_questions, setall_questions] = useState([]);
  const [all_categories, setall_categories] = useState([]);
  const [loading, isloading] = useState(true);
  const [loadingpagination, setloadingpagination] = useState(false);
  const all_Questions_redux = useSelector((state) => state.app.allquestions);
  const [resetQuestion, setresetQuestion] = useState(false);
  useEffect(() => {
    if (resetQuestion) {
      setall_questions(all_Questions_redux);
    } else {
      setall_questions([...all_questions, ...all_Questions_redux]);
    }
  }, [all_Questions_redux]);

  useEffect(() => {
    //get all questions 10 counts per page (page-counter)
    (async () => {
      if (all_questions.length === 0) {
        const answer = await Allquestions(++pagination_counter);
        if (answer) {
          dispatch(addAllQuestions(answer));
        }
        isloading(false);
      }
    })();

    //get all categories
    (async () => {
      const categories = await allCategories();
      if (categories) console.log("BEFORE", categories);
      categories.map((x) => { });
      if (categories) console.log("CATEGORIES", categories);
      setall_categories(categories);
    })();
  }, []);

  //get questions depeding upon filter

  const filterQuestionsFuction = async (data) => {
    setresetQuestion(true);
    if (data == "") {
      //if no category select, reset question with pagination

      isloading(true);
      filtertiondataStorage = [];
      morePagination();
      return;
    } else {
      //reset filter pagination on new category select
      filterpagecounter = 0;

      filtertiondataStorage = data;
    }

    //get filterq questions  qith pagnination
    const result_filter = await filterQuestions(
      filtertiondataStorage,
      ++filterpagecounter
    );

    if (result_filter) {
      // update redux
      dispatch(addAllQuestions(result_filter));
    }
    isloading(false);
  };

  //COSMIC DATA
  const data_grapgql = useStaticQuery(graphql`
    {
      allCosmicjsPopularQuestions {
        nodes {
            metadata {
                answer
                question
                user
                id
                user_avatar {
                    url
                }
                user_affiliation
                user_location
                user_name
                user_specialities
                subtopic_icon
                meta_image {
                    url
                }
            }
        }
      }
      allCosmicjsQuestions {
        edges {
          node {
            slug
            metadata {
              heading
              sign_up_text
              sub_heading
            }
          }
        }
      }
    }
  `);

  console.log("POPULAR QUESTIONS: ", data_grapgql.allCosmicjsPopularQuestions.nodes)

  //COSMIC SLUGS
  var content_header;
  var content_question_trending;
  data_grapgql.allCosmicjsQuestions.edges.map((x, y) => {
    switch (x.node.slug) {
      case "header-answers":
        content_header = x.node.metadata;
        break;
      case "trending-questions":
        content_question_trending = x.node.metadata;
    }
  });

  //PAGINATION  COUNTER WITHOUT FILTER
  const morePagination = async () => {
    setresetQuestion(false);
    if (filtertiondataStorage.length > 0) {
      morePaginationFilters();
      return;
    }
    // document.querySelector(".configuration").scrollIntoViewIfNeeded();
    setloadingpagination(true);
    const answer = await Allquestions(++pagination_counter);
    if (answer) {
      dispatch(addAllQuestions(answer));
    }
    isloading(false);
    setloadingpagination(false);
  };

  //PAGINATION  COUNTER  FILTER
  const morePaginationFilters = async () => {
    setresetQuestion(false);
    //document.querySelector(".configuration").scrollIntoViewIfNeeded();
    setloadingpagination(true);
    const answer = await filterQuestions(
      filtertiondataStorage,
      ++filterpagecounter
    );
    if (answer) {
      dispatch(addAllQuestions(answer));
    }
    setloadingpagination(false);
  };

  //JSX

  return (
    <>
      <div className="qustionsPage">
        <div className="content">
          <Row>
            <Col xs={12}>
              <Heading
                heading={content_header.heading}
                subheading={content_header.sub_heading}
                link={content_header.sign_up_text}
              />
            </Col>

            <Col xs={12}>
              <PopularQuestions
                content={data_grapgql.allCosmicjsPopularQuestions.nodes}
              />
            </Col>
          </Row>
          <Row className="swap-mobile-flex">
            <Col xs={12} sm={9}>
              <div className="post">
                <div className="configuration">
                  <h2> All Questions</h2>
                </div>
                {all_questions.length == 0 && loading === false
                  ? "No data found."
                  : null}
                {loading === true ? (
                  <Skeleton count={5} />
                ) : (
                    all_questions.map((data) => {
                      var alldecipline = [];

                      if (data.best_answer != null) {
                        data.best_answer.created_by.specialities.map((x, y) => {
                          if (y <= 1) {
                            alldecipline.push(x.description);
                          }
                        });
                      } else {
                        if (data.answers.length > 0) {
                          data.answers[0].created_by.specialities.map((x, y) => {
                            if (y <= 1) {
                              alldecipline.push(x.description);
                            }
                          });
                        }
                      }
                      console.log(alldecipline.toString());
                      return (
                        <Post
                          affiliation={
                            data.best_answer != null
                              ? data.best_answer.created_by.affiliation
                              : data.answers.length > 0
                                ? data.answers[0].created_by.affiliation
                                : null
                          }
                          address={
                            data.best_answer != null
                              ? data.best_answer.created_by.city +
                              ", " +
                              data.best_answer.created_by.country
                              : data.answers.length > 0
                                ? data.answers[0].created_by.city +
                                ", " +
                                data.answers[0].created_by.country
                                : null
                          }
                          _id={data.id}
                          title={data.description}
                          img={
                            data.best_answer != null
                              ? data.best_answer.created_by.url_avatar
                              : data.answers.length > 0
                                ? data.answers[0].created_by.url_avatar
                                : null
                          }
                          name={
                            data.best_answer != null
                              ? data.best_answer.created_by.first_name +
                              " " +
                              data.best_answer.created_by.last_name
                              : data.answers.length > 0
                                ? data.answers[0].created_by.first_name +
                                " " +
                                data.answers[0].created_by.last_name
                                : null
                          }
                          designation={alldecipline.toString()}
                          answer={
                            data.best_answer != null
                              ? data.best_answer.description
                              : data.answers.length > 0
                                ? data.answers[0].description
                                : null
                          }
                          tag={
                            data.subtopics && data.subtopics.length > 0
                              ? data.subtopics[0].url
                              : null
                          }
                          tag_title={
                            data.subtopics && data.subtopics.length > 0
                              ? data.subtopics[0].description
                              : null
                          }
                          answers={data.answers_counter}
                          questionid={data.id}
                        />
                      );
                    })
                  )}
              </div>
              {loadingpagination ? (
                <div className="seemore">Loading ...</div>
              ) : (
                  <div className="seemore" onClick={morePagination}>
                    {" "}
                  See More Questions
                  </div>
                )}
            </Col>
            <Col xs={12} sm={3} className="cat_section">
              <Dropdown className="categories-for-mobile">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  Categories
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {all_categories.map((data, count) => {
                    return (
                      <Dropdown.Item>
                        <Categories
                          title={data.description}
                          activeCount={"count" + count}
                          image={data.url}
                          setloader={isloading}
                          filter={filterQuestionsFuction}
                          fullobject={data}
                        />
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <div className="desktop-categ-sec">
                <h2>Categories</h2>

                {all_categories.map((data, count) => {
                  return (
                    <Categories
                      title={data.description}
                      activeCount={"count" + count}
                      image={data.url}
                      setloader={isloading}
                      filter={filterQuestionsFuction}
                      fullobject={data}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
