import React from "react";
import { Link } from "gatsby";
import User from "./user.js";
import Social from "./social.js";

export default function Post({
  title,
  img,
  name,
  designation,
  thumbnail,
  answer,
  tag,
  tag_title,
  questionid = "",
  answers,
  affiliation,
  address,
  _id,
}) {
  return (
    <div className="post">
      <div className="details">
        <Link to={`/questions/detail?id=${questionid}`}>
          <h3>{title}</h3>
        </Link>

        <User
          affiliation={affiliation}
          address={address}
          img={img}
          name={name}
          designation={designation}
        />
        <p className="solution">{answer}</p>

        <Social
          _id={_id}
          replies={answers}
          tag={tag}
          tag_title={tag_title}
          title={title}
        />
      </div>
    </div>
  );
}
