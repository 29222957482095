import API from "../utils/axios";
import { getTokenFromLocalStorage } from "../utils/local-storage";
var headers;
var all_detail_questions = [];
var url;

export const filterQuestions = async (data = [], pagenumber) => {
  const authExist = getTokenFromLocalStorage();
  all_detail_questions = [];
  //custom together all sib topixs
  var querry_string_custom_url = "";
  const querry_string_custom = data.map((x) => {
    return querry_string_custom_url + "&subtopic_ids=" + x.id;
  });
  const response = await API.get(
    "/questions/topic/" +
      data[0].topic_id +
      "?page_size=10&page_number=" +
      pagenumber +
      "&filter_type=favorited" +
      querry_string_custom.toString().replace(/,/g, "")
  )
    .then((responsed) => {
      return responsed;
    })
    .catch((error) => {
      return error;
    });

  if (authExist === "") {
    if (response.data.data.length > 0) {
      const all_questions_id = response.data.data.map((data) => {
        if (data.question) {
          return data.question.id;
        }
      });

      const check_all_ids_for_questions = new Promise((res, rej) => {
        all_questions_id.map((data, count) => {
          API.get("/questions/public/" + data)
            .then((x) => {
              all_detail_questions.push(x.data);

              if (count === all_questions_id.length - 1) {
                res();
              }
            })
            .catch(() => {
              if (count === all_questions_id.length - 1) {
                res();
              }
            });
        });
      });

      await check_all_ids_for_questions;

      return all_detail_questions;
    } else {
      return [];
    }
  }

  if (authExist != "") {
    headers = {
      "Content-Type": "application/json",
      authorization: `Token ${authExist} `,
    };
    if (response.data.data.length > 0) {
      const all_questions_id = response.data.data.map((data) => {
        if (data.question) {
          return data.question.id;
        } else {
          return data.id;
        }
      });

      const check_all_ids_for_questions_reg = new Promise((res, rej) => {
        all_questions_id.map((data, count) => {
          API.get(`/questions/${data}`, { headers }).then((x) => {
            all_detail_questions.push(x.data);

            if (count === all_questions_id.length - 1) {
              res();
            }
          });
        });
      });

      await check_all_ids_for_questions_reg;

      return all_detail_questions;
    } else {
      return [];
    }
  }
};
