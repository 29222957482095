//all imports
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./assets/css/style.scss";
import Sliderdata from "./sliderdata/data.js";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
//main function
export default function PopularQuestions({ content = [] }) {
  //slick slider
  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3.5,
    slidesToScroll: 3,
    infinite: false,
    autoplay: false,
    centerPadding: "40px",
    autoplaySpeed: 5000,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="PopularQuestions">
      <div className="content">
        <h6>POPULAR QUESTIONS</h6>
        <Slider {...settings}>
          {content.map((x) => {
            const specialities = x.metadata.user_specialities

            if (!x.hasOwnProperty("title")) {
              return (
                <Sliderdata
                  username={`${x.metadata.user_name}${(specialities && specialities !== "") ? `, ${specialities}` : ""}`}
                  userimage={
                    x.metadata.user_avatar && x.metadata.user_avatar.url
                  }
                  question={x.metadata.question}
                  answer={x.metadata.answer}
                  id={x.metadata.id}
                />
              );
            }
          })}
        </Slider>

        {/*   <Link to="/questions" className="morelink">
          See More Questions
          <img src={more} />
        </Link> */}
      </div>
    </div>
  );
}
